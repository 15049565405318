//字节转换为G,M,kb
let transformByte = (size) => {
    if (!size) {
      return '0B';
    }
    var num = 1024.0; // byte

    if (size < num) {
      return size + 'B';
    }
    if (size < Math.pow(num, 2)) {
      return (size / num).toFixed(2) + 'K';
    } // kb
    if (size < Math.pow(num, 3)) {
      return (size / Math.pow(num, 2)).toFixed(2) + 'M';
    } // M
    if (size < Math.pow(num, 4)) {
      return (size / Math.pow(num, 3)).toFixed(2) + 'G';
    } // G
    return (size / Math.pow(num, 4)).toFixed(2) + 'T'; // T
  }

  // 过滤时间，将时间的T去掉
let formatTime = (val) => {
    let s = String(val).replace("T", " ");
    return s;
}
export default {
  transformByte,
  formatTime
}
