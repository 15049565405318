import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    username:'',
    // 邮箱
    email:localStorage.getItem('email'),
    // 品牌
    company_name:localStorage.getItem('company_name'),
    openId: ''
  },
  mutations: {
    setOpenId(state,val){
      state.openId = val
    }

  },
  actions: {
  },

  modules: {
  }
})
