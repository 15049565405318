import Vue from 'vue'
import {Badge,Footer,Switch,Message,Progress,Alert,Cascader,Row,DropdownItem,DropdownMenu,Dropdown,MenuItem,Divider,Menu,Submenu,Select,Container,Aside,Header,Main,Option,Avatar,Col,DatePicker,TimePicker,CheckboxButton, Form,FormItem,Button, Input, Checkbox, CheckboxGroup, Dialog, Radio, RadioGroup, Pagination, Upload, Rate, Tag, Tree, Collapse,
  CollapseItem, ButtonGroup, Table, TableColumn, Autocomplete, CascaderPanel, Tooltip, Transfer, Loading, Tabs, TabPane, InputNumber, Image, Link, Popover, Drawer, MessageBox}
  from 'element-ui'
Vue.use(Badge)
Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 }
Vue.use(Button)
Vue.use(Input)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Dialog)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Pagination)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Select)
Vue.use(Option)
Vue.use(Avatar)
Vue.use(Col)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(CheckboxButton)
Vue.use(Container)
Vue.use(Aside)
Vue.use(Header)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(Divider)
Vue.use(MenuItem)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Row)
Vue.use(Cascader)
Vue.use(Upload)
Vue.use(Rate)
Vue.use(Tag)
Vue.use(Alert)
Vue.use(Progress)
Vue.prototype.$message = Message
Vue.use(Tree)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(ButtonGroup)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Autocomplete)
Vue.use(CascaderPanel)
Vue.use(Tooltip)
Vue.use(Transfer)
Vue.use(Loading)
Vue.use(Switch)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(InputNumber)
Vue.use(Image)
Vue.use(Link)
Vue.use(Popover)
Vue.use(Drawer)
Vue.prototype.$confirm = MessageBox.confirm;
