import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import axios from '../https'
const Login = () => import('@/pages/Login.vue') // 登录
const WorkLibrary = () => import('@/pages/WorkLibrary.vue') // 作品库

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
const routes = [
  {
    path: '/work_library',
    name: 'WorkLibrary',
    component: WorkLibrary
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// let email = localStorage.getItem('email');

// 信息是否完善
// function hasComplete(path) {
//   return new Promise((resolve, reject) => {
//     if(email != null && email != '' || path == '/all_materials') {
//        try {
//         axios.get('accounts/get_complete_status/')
//             .then(res => {
//               if (res.data.status == 0) {
//                 store.commit("setUserInfo", {
//                   username: res.data.data.true_name,
//                   department: res.data.data.department,
//                   position: res.data.data.position
//                 });
//                 resolve(true);
//               } else {
//                 resolve(false);
//               }
//             }).catch(error => {
//               console.log(error)
//             })
//       } catch (error) {
//         reject()
//       }
//     }else{
//       resolve(true);
//     }
//   })
// }

// const nextRoute = ['Home', 'editUers', 'allMaterials', 'materialDetail', 'MaterialUpload', 'MyCollection', 'MyMaterial', 'Tag', 'TagGroup', 'Recycle', 'WorkLibrary', 'InteligenceCompose', 'CreativeLibrary', 'ScriptRecommend', 'HotTopic',
//  'OperationManage', 'AccountAnalysis', 'VideoExpression', 'Uploading', 'watchlist', 'Publish', 'UserInfo', 'TestAudio', 'TestVideo','CommonStyle'];
// 刷新页面会调用一下
// hasComplete()

// const nextRoute = ['WorkLibrary']
// router.beforeEach((to, from, next) => {
//   next()
//   let open_id = localStorage.getItem('open_id')
//   if (open_id === null || open_id === '') { //未登录
//     if (nextRoute.indexOf(to.name) >= 0) {
//       next('/login')
//     }else{
//       next()
//     }
//   }else{ //已登录
//       if (to.path === '/' || (nextRoute.indexOf(to.name) == -1 )){
//         if (to.path === '/login' && (open_id === null || open_id === '')) {
//           next('/login')
//         }else{
//             return next({ path: '/work_library' })
//         }
//       }else {
//         next()
//       }
//   }
// })



export default router
